// Primary Colors
$color-primary: #0367b9;
$color-primary-dark: #024c8b;
$color-primary-light: #098efb;

// Secondary Colors
$color-secondary: #8ac4bc;
$color-secondary-dark: #5a9c97;
$color-secondary-light: #cfe7e4;

// Background Color
// $color-background: #EDDED9;
$color-background: #ffffff;
$color-surface: #ffffff;
$color-error: #d12c00;

// Fonts Color
$color-font-dark: #000000;
$color-font-light: #ffffff;

// Shadow Colors
$color-dark-shadow: rgba(90, 97, 105, 0.1);
$color-light-shadow: rgba(165, 182, 201, 0.1);
$color-nav-active-hover-shadow: $color-primary-light;

// Color Variables
// Title, Subtitle, Body
$title-font-color: $color-primary;
$subtitle-font-color: $color-primary-dark;
$body-font-color: $color-font-dark;

$title-font-light-color: $color-secondary;
$subtitle-font-light-color: $color-secondary-dark;
$body-font-light-color: $color-font-light;

// Background
$background-color: $color-background;

// Navbar
// $navbar-top-background-color: $color-surface;
$navbar-background-color: $color-surface;
$navbar-active-link-background-color: $color-dark-shadow;
$navbar-active-link-font-color: $color-primary-dark;

// a (Link)
$a-color: $color-primary;
$a-hover-color: $color-primary-light;

// Button
$button-text-color: $color-secondary-light;
$button-background-color: $color-primary;

:export {
  color: $color-primary-dark;
}
